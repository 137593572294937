import '../../styles/desktop/Error.scss';

const Error = () => {
  return (
    <div>
      Error Something Went Wrong
    </div>
  );
}

export default Error;